import React from "react";
import homeStyles from "../css/home.module.css";
import { Link } from "react-router-dom";

function calculateRemainingTime(countDownDate: number) {
  let timeLeft: number = countDownDate - Date.now();

  if (timeLeft < 0)
    return {
      timeLeft,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  return {
    timeLeft,
    days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
    hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
  };
}

export default function Home() {
  // const betaLaunchDate: number = new Date(
  //   "Dec 01, 2022 20:00:00 GMT+1"
  // ).getTime();

  // const timeLeftObj = calculateRemainingTime(betaLaunchDate);
  // const [state, setState] = React.useState(
  //   `${timeLeftObj.days}d ${timeLeftObj.hours}h ${timeLeftObj.minutes}m ${timeLeftObj.seconds}s`
  // );

  // setInterval(() => {
  //   let newTimeLeftObj = calculateRemainingTime(betaLaunchDate);
  //   setState(
  //     `${newTimeLeftObj.days}d ${newTimeLeftObj.hours}h ${newTimeLeftObj.minutes}m ${newTimeLeftObj.seconds}s`
  //   );
  // }, 1000);

  return (
    <div className={homeStyles.Home}>
      <div className={homeStyles.TitleContainer}>
        <p className={homeStyles.W}>beta available now</p>
        <h1>vays</h1>
        <h2>the only discord bot you'll ever need</h2>
        <div className={homeStyles.Buttons}>
          <Link to="/discord" className="Button" role="button">
            support server
          </Link>
          <Link to="/invite" className="Button" role="button">
            invite the bot
          </Link>
        </div>
      </div>
      <div className={homeStyles.SecondContainer}>
        <div className={homeStyles.Sex}>
          <h2>Why vays?</h2>
          <div className={homeStyles.Items}>
            <div className={homeStyles.Item}>
              <div className={homeStyles.Text}>
                <div className={homeStyles.ItemTitle}>unique features</div>
                <div className={homeStyles.ItemText}>
                  vays has a lot of unique features like last.fm and more
                </div>
              </div>
            </div>
            <div className={homeStyles.Item}>
              <div className={homeStyles.Text}>
                <div className={homeStyles.ItemTitle}>cool bot</div>
                <div className={homeStyles.ItemText}>
                  vays is a very cool bot, so add it
                </div>
              </div>
            </div>
            <div className={homeStyles.Item}>
              <div className={homeStyles.Text}>
                <div className={homeStyles.ItemTitle}>frequent updates</div>
                <div className={homeStyles.ItemText}>
                  our developer team releases an update at least once a week
                </div>
              </div>
            </div>
            <div className={homeStyles.Item}>
              <div className={homeStyles.Text}>
                <div className={homeStyles.ItemTitle}>not skidded</div>
                <div className={homeStyles.ItemText}>
                  our bot is not skidded, unlike termit
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img src="/assets/this-weird-black-thing.svg" alt="sex" /> */}
        <svg
          width="1441"
          height="222"
          viewBox="0 0 1441 222"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M294 103.5C153.5 194.5 0 45.0001 0 45.0001V221.5H1440.73V152C1440.73 152 1270 134 974 45C678 -44 434.5 12.5001 294 103.5Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <footer className={homeStyles.Footer}>© 2022 1pears</footer>
    </div>
  );
}

import React from "react";
import styles from "../css/alert.module.css";

export default function Alert(props: {
  title: string;
  subtitle: string;
  description: string;
}) {
  return (
    <div className={styles.Alert}>
      <div className={styles.Container}>
        <h1 className={styles.Title}>{props.title}</h1>
        <h2 className={styles.SubTitle}>{props.subtitle}</h2>
        <p className={styles.Description}>{props.description}</p>
      </div>
    </div>
  );
}

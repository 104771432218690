import React from "react";
import { Loader } from ".";

export default function Invite() {
  window.location.replace(
    "https://discord.com/oauth2/authorize?client_id=980430011628666911&permissions=8&redirect_uri=https%3A%2F%2Fvays.ml%2Fbot%2Fauthorized&response_type=code&scope=bot%20applications.commands"
  );

  return <Loader />;
}

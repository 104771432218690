import React from "react";
import { Alert, Loader } from ".";

export default function BotAuthorized() {
  const search = new URLSearchParams(window.location.search);
  const guildId = search.get("guild_id");

  if (!guildId) {
    window.location.replace("/");
    return <Loader />;
  }

  window.history.pushState("", "", `/bot/authorized?guild_id=${guildId}`);

  return (
    <div className="Authorized">
      <Alert
        title="200"
        subtitle="Authorized"
        description={`vays has been added to ${guildId}`}
      />
    </div>
  );
}

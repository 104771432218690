import React from "react";
import styles from "../css/privacy.module.css";

export default function Privacy() {
  return (
    <div className={styles.Privacy}>
      <div className={styles.Container}>
        <div className={styles.Text}>
          <h1 className={styles.Title}>Privacy Policy</h1>
          <h2 className={styles.Subtitle}> Updated 30.11.2022 5:23PM GMT+1 </h2>
          <div className={styles.ActualText}>
            <span className={styles.Bolded}> Data we collect </span> <br />
            <p>
              Our bot may collect data like your Discord ID, Username, Tag and
              last deleted message on a channel from Discord. It will also store
              your Last.fm username in its database (if earlier provided by
              user using the <span className={styles.Highlight}>/fm set</span>{" "}
              command).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

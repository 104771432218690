import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Components from "./components";

function App() {
  const theme =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";

  document.documentElement.setAttribute("data-theme", theme);

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newTheme = event.matches ? "dark" : "light";
      document.documentElement.setAttribute("data-theme", newTheme);
    });

  window.onload = () => {
    const background = document.querySelector(".Background");
    if (!background) return;

    background.setAttribute("loaded", "true");
  };
  window.addEventListener("resize", () => {
    if (window.innerWidth <= 333) {
      document.body.innerHTML = `<p class="SmallDeviceError"> This site won't work on your device. </p>`;
      window.addEventListener("resize", () => {
        if (window.innerWidth > 333) window.location.reload();
      });
    }
  });

  if (window.innerWidth <= 333)
    document.body.innerHTML = `<p class="SmallDeviceError"> This site won't work on your device. </p>`;

  return (
    <div className="App">
      <div className="Background"></div>
      <Router>
        <Routes>
          {/* <Route path="/dash" element={<Dashboard.Dashboard />} /> */}

          <Route path="/" element={<Components.Home />} />
          <Route path="/discord" element={<Components.Discord />} />
          <Route path="/invite" element={<Components.Invite />} />
          <Route path="/privacy" element={<Components.Privacy />} />
          {/* <Route path="/countdown" element={<Countdown />} /> */}
          <Route
            path="*"
            element={
              <Components.Alert
                title="404"
                subtitle="not found"
                description={`this site could not be found.`}
              />
            }
          />
          <Route
            path="/bot/authorized"
            element={<Components.BotAuthorized />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
